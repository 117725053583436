// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TShirtSize = {
  "S": "S",
  "M": "M",
  "L": "L",
  "XL": "XL",
  "XXL": "XXL",
  "XXXL": "XXXL"
};

const AuthLogType = {
  "LOGIN": "LOGIN",
  "REGISTER": "REGISTER",
  "LOGOUT": "LOGOUT"
};

const { ViewLog, PageView, UserDetail, AuthLog } = initSchema(schema);

export {
  ViewLog,
  PageView,
  UserDetail,
  AuthLog,
  TShirtSize,
  AuthLogType
};