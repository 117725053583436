import { Link } from "react-router-dom";
import { useAuthContext } from "../contexts/Auth";

const PortalLayout = ({ children }) => {
  const { logout } = useAuthContext();

  return (
    <>
      <nav>

      </nav>
        {children}
      <div>
        
      </div>
    </>
  );
};

export default PortalLayout;
