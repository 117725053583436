import { DataStore } from "aws-amplify";
import { AuthLog, ViewLog } from "../models";

export const logAuthActivity = async (type, email) => {
  try {
    await DataStore.save(new AuthLog({ type, email }));
  } catch (e) {
    console.log("Failed to log Activity: ", type, e);
  }
};

export const logPageView = async (path, email, duration) => {
  try {
    DataStore.save(new ViewLog({ path, email, duration }));
  } catch (e) {
    console.log("Failed to Track PageView: ", path, e);
  }
};
