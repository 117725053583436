import LogoImg from "../assets/images/AMS-white.png";

const AuthLayout = ({ children }) => {
  return (
    <main 
    className="bg-red pb-5"
    style={{backgroundColor: 'black'}}
    >
      <div 
      className="logo-container"
      style={{backgroundColor: 'black'}}
      >
        <img
          className="img-fluid d-block mx-auto hd-logo py-4"
          src={LogoImg}
          alt="logo"
        />
      </div>
      {children}
    </main>
  );
};

export default AuthLayout;
