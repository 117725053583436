/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:9b921d26-681c-44c0-8f4d-931fc36b15d1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_sj1SFsNCI",
  "aws_user_pools_web_client_id": "3ahl7arp6jf08kk0ugm3f4735u",
  "oauth": {},
  "aws_appsync_graphqlEndpoint": "https://wg4pcrmioffzbmmgra2debwsoy.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-avyj4czrwzam5dli4ctssx5hny",
  "aws_cloud_logic_custom": [],
  "aws_mobile_analytics_app_id": "bd6a84dcf49f4da3bf9869d82779fc57",
  "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
