import LogoImg from "../assets/images/AMS-black.png";
import "./css/Loading.css";

const Loading = () => {
  return (
    <main className="d-flex justify-content-center align-items-center">
      <img className="logo" src={LogoImg} alt="Camput Ambassador" />
      <div className="nb-spinner"></div>
    </main>
  );
};

export default Loading;
