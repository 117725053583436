import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import loadable from "@loadable/component";

import Loading from "./pages/Loading";

import AuthLayout from "./components/AuthLayout";
import PortalLayout from "./components/PortalLayout";
import DemoLayout from "./components/PortalLayout";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import { useAuthContext } from "./contexts/Auth";
import { logPageView } from "./utils/tracker";
import { usePrevious } from "./hooks/usePrevious";

const DemoAgenda = loadable(() => import("./pages/demo3/pages/agenda"));
// const DemoAttendees = loadable(() => import("./pages/demo3/pages/attendees"));
const DemoBreakouts = loadable(() => import("./pages/demo3/pages/breakouts"));
const DemoGeneralSession = loadable(() => import("./pages/demo3/pages/general-session"));
const DemoGeneralSessionBackup = loadable(() =>
  import("./pages/demo3/pages/general-session-backup")
);
const DemoHome = loadable(() => import("./pages/demo3/pages/home"));
const DemoHomebackup = loadable(() => import("./pages/demo3/pages/home-backup"));
const DemoResources = loadable(() => import("./pages/demo3/pages/resources"));
const DemoSpeakers = loadable(() => import("./pages/demo3/pages/speakers"));
const DemoSpeakerMembers = loadable(() => import("./pages/demo3/pages/speakerMember"));
const DemoSponsors = loadable(() => import("./pages/demo3/pages/sponsors"));
const DemoExhibitors = loadable(() => import("./pages/demo3/pages/exhibitors"));
const DemoTechSupport = loadable(() => import("./pages/demo3/pages/tech-support"));
const DemoVOD = loadable(() => import("./pages/demo3/pages/vod"));
const DemoVCT = loadable(() => import("./pages/demo3/pages/vct-rooms"));
const DemoBO1 = loadable(() => import("./pages/demo3/pages/bo1"));
const DemoBO2 = loadable(() => import("./pages/demo3/pages/bo2"));
const DemoBO3 = loadable(() => import("./pages/demo3/pages/bo3"));
const DemoBO4 = loadable(() => import("./pages/demo3/pages/bo4"));
const DemoBO5 = loadable(() => import("./pages/demo3/pages/bo5"));



const Landing = loadable(() => import("./pages/landing"));
const Demo1 = loadable(() => import("./pages/demo1"));
const Demo2 = loadable(() => import("./pages/demo2"));
const Request = loadable(() => import("./pages/request"));
const Features = loadable(() => import("./pages/features"));
const Breakouts = loadable(() => import("./pages/breakouts"));
const Bo1 = loadable(() => import("./pages/breakouts/bo1"));
const Bo2 = loadable(() => import("./pages/breakouts/bo2"));
const Bo3 = loadable(() => import("./pages/breakouts/bo3"));
const Bo4 = loadable(() => import("./pages/breakouts/bo4"));
const Bo5 = loadable(() => import("./pages/breakouts/bo5"));
const Home = loadable(() => import("./pages/home"));
const Login = loadable(() => import("./pages/login"));
const Register = loadable(() => import("./pages/register"));
const ConfirmCode = loadable(() => import("./pages/confirm-code"));
const ForgotPassword = loadable(() => import("./pages/forgot-password"));
const ResetPassword = loadable(() => import("./pages/reset-password"));
// const Speakers = loadable(() => import("./pages/speakers"));
// const TechSupport = loadable(() => import("./pages/tech-support"));
// const Vod = loadable(() => import("./pages/vod"));
const Profile = loadable(() => import("./pages/profile"));

function AuthRoutes() {
  return (
    <AuthLayout>
      <Switch>
        <UnauthenticatedRoute exact path="/login">
          <Login />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/forgot-password">
          <ForgotPassword />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/register">
          <Register />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/confirm-code">
          <ConfirmCode />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path="/reset-password">
          <ResetPassword />
        </UnauthenticatedRoute>
        <Route exact path="/profile">
          <Profile />
        </Route>
      </Switch>
    </AuthLayout>
  );
}

function PortalRoutes() {
  return (
    <PortalLayout>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/landing">
          <Landing />
        </Route>
        <Route exact path="/demo1">
          <Demo1 />
        </Route>
        <Route exact path="/demo2">
          <Demo2 />
        </Route>
        <Route exact path="/request">
          <Request />
        </Route>
        <Route exact path="/features">
          <Features />
        </Route>
        <Route exact path="/breakouts">
          <Breakouts />
        </Route>
        <Route exact path="/bo1">
          <Bo1 />
        </Route>
        <Route exact path="/bo2">
          <Bo2 />
        </Route>
        <Route exact path="/bo3">
          <Bo3 />
        </Route>
        <Route exact path="/bo4">
          <Bo4 />
        </Route>
        <Route exact path="/bo5">
          <Bo5 />
        </Route>
        <Route exact path="/demo3">
          <DemoHome />
        </Route>
        <Route exact path="/demo-home">
          <DemoHome />
        </Route>
        <Route exact path="/demo-home-backup">
          <DemoHomebackup />
        </Route>
        <Route exact path="/demo-agenda">
          <DemoAgenda />
        </Route>
        <Route exact path="/demo-attendees">
          <Features />
        </Route>
        <Route exact path="/demo-breakouts">
          <DemoBreakouts />
        </Route>
        <Route exact path="/demo-general-session">
          <DemoGeneralSession />
        </Route>
        <Route exact path="/demo-general-session-backup">
          <DemoGeneralSessionBackup />
        </Route>
        <Route exact path="/demo-resources">
          <DemoResources />
        </Route>
        <Route exact path="/demo-speakers">
          <DemoSpeakers />
        </Route>
        <Route exact path="/demo-speaker-members">
          <DemoSpeakerMembers />
        </Route>
        <Route exact path="/demo-sponsors">
          <DemoSponsors />
        </Route>
        <Route exact path="/demo-exhibitors">
          <DemoExhibitors />
        </Route>
        <Route exact path="/demo-tech-support">
          <DemoTechSupport />
        </Route>
        <Route exact path="/demo-vod">
          <DemoVOD />
        </Route>
        <Route exact path="/demo-vct-rooms">
          <DemoVCT />
        </Route>
        <Route exact path="/demo-bo1">
          <DemoBO1 />
        </Route>
        <Route exact path="/demo-bo2">
          <DemoBO2 />
        </Route>
        <Route exact path="/demo-bo3">
          <DemoBO3 />
        </Route>
        <Route exact path="/demo-bo4">
          <DemoBO4 />
        </Route>
        <Route exact path="/demo-bo5">
          <DemoBO5 />
        </Route>
      </Switch>
    </PortalLayout>
  );
}

function DemoRoutes() {
  return (
    <DemoLayout>
      <Route exact path="/demo">
        TEST
      </Route>
    </DemoLayout>
  )
}

let startTime;

export default function Routes() {
  const location = useLocation();
  const { isAuthLoading, user } = useAuthContext();

  const prevLocation = usePrevious(location.pathname);

  useEffect(() => {
    if (startTime) {
      const duration = new Date() - startTime;
      startTime = new Date();
      logPageView(prevLocation, user.email, duration);
    } else {
      startTime = new Date();
    }
  }, [location.pathname, user]);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (startTime) {
        const duration = new Date() - startTime;
        startTime = new Date();
        logPageView(prevLocation, user.email, duration);
      }
    };
  }, []);

  if (isAuthLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route
        path={[
          "/login",
          "/register",
          "/forgot-password",
          "/confirm-code",
          "/profile",
          "/reset-password",
        ]}
        component={AuthRoutes}
      />
      <Route
        path={[
          "/",
          "/breakouts",
          "/demo1",
          "/demo2",
          "/request",
          "/features",
          "/bo1",
          "/bo2",
          "/bo3",
          "/bo4",
          "/bo5",
          // "/demo",
          "/demo-agenda",
          "/demo-attendees",
          "/demo-home",
          "/demo-home-backup",
          "/demo-breakout/:category/:roomId",
          "/demo-breakouts",
          "/demo-general-session",
          "/demo-resources",
          "/demo-speakers",
          "/demo-speaker-members",
          "/demo-exhibitors",
          "/demo-sponsors",
          "/demo-tech-support",
          "/demo-vct-rooms",
          "/demo-vod",
          "/demo-bo1",
          "/demo-bo2",
          "/demo-bo3",
          "/demo-bo4",
          "/demo-bo5",
        ]}
        component={PortalRoutes}
      />
      <Route
        path={[
          "/demo",
        ]}
        component={DemoRoutes}
      />
      <Route>Not Found</Route>
    </Switch>
  );
}
