import { createContext, useContext, useEffect, useState } from "react";
import { Auth, DataStore } from "aws-amplify";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { logAuthActivity } from "../utils/tracker";
import { AuthLogType, UserDetail } from "../models";

const defaultValue = {
  isAuthLoading: false,
  isAuthenticated: false,
  user: null,
  profile: null,
  setIsAuthenticated: () => {},
  logout: () => {},
  setUser: () => {},
};

export const AuthContext = createContext(defaultValue);
export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      const currentUserInfo = await Auth.currentUserInfo();

      setUser(currentUserInfo.attributes);
      setIsAuthenticated(true);

      const [profile] = await DataStore.query(UserDetail, (u) =>
        u.email("eq", currentUserInfo.attributes.email)
      );

      console.log("Profile", user.attributes, profile);
      setProfile(profile);
    } catch (e) {
      console.log(e);
    }

    setIsAuthLoading(false);
  }

  async function logout() {
    await logAuthActivity(AuthLogType.LOGOUT, profile.email);

    await Auth.signOut();

    setIsAuthenticated(false);

    history.push("/login");
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthLoading,
        isAuthenticated,
        user,
        profile,
        setIsAuthenticated,
        logout,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
AuthContextProvider.defaultProps = {
  children: null,
};

export default AuthContextProvider;
